const data = [
	{
		name: 'cPanel',
		icon: 'Rust',
		license: true,
		pricings: [
			{
				id: 'cpanel-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps1-virginia',
			},
			{
				id: 'cpanel-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps2-virginia',
			},
			{
				id: 'cpanel-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps3-virginia',
			},
			{
				id: 'cpanel-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps4-virginia',
			},
		],
	},
	{
		name: 'Plesk',
		icon: 'Shopify',
		license: true,
		pricings: [
			{
				id: 'plesk-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps1-virginia',
			},
			{
				id: 'plesk-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps2-virginia',
			},
			{
				id: 'plesk-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps3-virginia',
			},
			{
				id: 'plesk-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps4-virginia',
			},
		],
	},
	{
		name: 'Amazon Linux 2023',
		icon: 'Sketch',
		pricings: [
			{
				id: 'docker-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/amazon-linux-1-aws-virginia',
			},
			{
				id: 'docker-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/amazon-linux-2-aws-virginia',
			},
			{
				id: 'docker-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/amazon-linux-3-aws-virginia',
			},
			{
				id: 'docker-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/amazon-linux-4-aws-virginia',
			},
		],
	},
];

export default data;
